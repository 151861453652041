#under
{
    color: aliceblue;
}

nav{
    background-color: #2f1d37;
    color: #948979;
}

#name,#name1
{
        font-family: "Playfair Display", serif;
        font-optical-sizing: auto;
        font-weight: "weight";
        font-style: normal;
}
.fa-facebook,.fa-telegram,.fa-whatsapp,.fa-linkedin,.fa-youtube{
    margin-left: 25px;
    font-size: 30px;
    margin-bottom:15px;
    margin-top: 30px;
}

.fa-facebook
{
    background-color: #285AEB;
    color: rgb(255, 255, 255);
    padding: 9px 15px;
    border-radius: 50%;
}

.fa-telegram{
    font-size: 40px;
    padding: 9px 15px;
    border-radius: 50%;
}

.fa-linkedin{
    background-color: #285AEB;
    color: rgb(255, 255, 255);
    padding: 8px 13px;
    border-radius: 50%;
}

/* .fa-instagram{
    background-image: radial-gradient(rgb(225, 48, 108),rgb(64, 93, 230),rgb(245, 96, 64));
    color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 5px;
}   */

.fa-instagram{
    background-image: radial-gradient(rgb(64, 93, 230),rgb(88, 81, 219),rgb(131, 58, 180));
    color: rgb(255, 255, 255);
    padding: 8px 13px;
    border-radius: 50%;
}

.fa-whatsapp{
    background-color: #23da54;
    color: rgb(255, 255, 255);
    padding: 8px 13px;
    border-radius: 50%;
}

.fa-youtube{
    background-color: #eb2828;
    color: rgb(255, 255, 255);
    padding: 8px 13px;
    border-radius: 50%;
}
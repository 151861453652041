#bg
{
    background-color: #492527;
    color: aliceblue;
    border-radius: 15px;
    width: 310px;
    padding: 12px;
    height: 350px;
    margin-left: 30px;
}

#t1
{
    margin-top: 100px;
    padding-left: 50px;
}

#bt1
{
    margin-left: 100px;
}

#img1
{
    border-radius: 20px;
}

#banner
{
    border-radius: 10px;
    border: solid black 2px;
}

#home
{
    background-image: url(home3.jpg);
    color: rgb(216, 216, 216);
    height: 700px;
    width: 98%;
    margin-top: 5px;
    margin-left: 15px;
    border-radius: 20px;
    padding-top: 200px;
    padding-left: 300px;

} 

#hgn
{
    background-color:#32012F;
}



#box
{
    background-image: linear-gradient(rgb(31, 31, 31),rgb(174, 119, 119));
    color: #ffffff;
    border-radius: 10px;
    width: 250;
    border: solid;
    margin-bottom: 10px;
    box-shadow: black;
}

#box:hover
{
    /* background-color: #32012F; */
    background-image: linear-gradient(rgb(174, 119, 119),rgb(79, 78, 78));
    color: #ffffff;
}

#text
{
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: rgb(0, 0, 0);
}

#text2
{
    font-family: 'Times New Roman', Times, serif;
    text-shadow: rgb(0, 0, 0);
}

#text,#text2
{
  
    transition: transform .5s; /* Animation */
    margin: 0 auto;
}

#text,#text2:hover 
{
    -ms-transform: 'scale(1.3)'; /* IE 9 */
    -webkit-transform: 'scale(1.5)'; /* Safari 3-8 */
    transform: 'scale(1.1)';
}

#btn{
    margin-top: 100px;
    margin-left: 300px;
    padding: 5px 10px;
    
}



#pro
{
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
    border: #000000 5px;
}


#banner
{
    border-radius: 25px;
}

#icon
{
    font-size: 25px; 
}

#p1{
    border-radius: 50%;
}







